import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import './404.scss'

export default function ErrorPage() {
    return (
        <Layout>
            <div className="page_error">
                <div className="block center">
                    <div className="relative">
                        <h2>Cette page n'existe pas</h2>
                        <Link to="/">Retour à l'accueil</Link>
                    </div>
                </div>
            </div>

        </Layout>
    )
}